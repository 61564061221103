import { Icon, toast } from '@/Components'
import { PageProps } from '@/types'
import { usePage } from '@inertiajs/react'
import { useEffect } from 'react'

export function useToast() {
  const { toasts } = usePage<PageProps>().props

  useEffect(() => {
    if (toasts && toasts.length > 0) {
      toasts.forEach((t) => {
        switch (t.type) {
          case 'success':
            toast({
              heading: t.message,
              icon: <Icon className='text-green-600'>check_small</Icon>
            })
            break
          case 'failed':
            toast({
              heading: t.message,
              icon: <Icon className='text-error'>error</Icon>
            })
            break
        }
      })
    }
  }, [toasts])
}
